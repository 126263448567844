import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import {
  Home,
  Error,
  Register,
  Login,
  Verify,
  Dashboard,
  ProtectedRoute,
  ForgotPassword,
  ResetPassword,
} from './pages';
import Navbar from './components/Navbar';
import { AuthProvider } from './utils/AuthContext';

function App() {
  
  return (
    <Router>
      <AuthProvider>
        <Navbar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/login' element={<Login />} />
          <Route path='/register' element={<Register />} />
          <Route element={<ProtectedRoute />}>
            <Route path="/dashboard" element={<Dashboard />} />
          </Route>
          <Route path='/forgot-password' element={<ForgotPassword />} />
          <Route path='/user/verify-email' element={<Verify />} />
          <Route path='/user/reset-password' element={<ResetPassword />} />
          <Route path='*' element={<Error />} />
        </Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;
