import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useGlobalContext } from '../utils/AuthContext';

const ProtectedRoute = ({ children, ...rest }) => {
  const { user } = useGlobalContext();

  return (
    user ? <Outlet /> : <Navigate to='/' />
  );
};
export default ProtectedRoute;
