import React, { useContext, useState, useEffect, createContext } from "react";
import Cookies from "universal-cookie";
import axiosInstance from "../api/axios";

const cookies = new Cookies();
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [user, setUser] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        checkUserStatus()
    }, [])

    const saveUser = (user) => {
        setUser(user);
    };

    const removeUser = () => {
        setUser(null);
    };

    const fetchUser = async () => {
        try {
            const { data } = await axiosInstance.get(`/users/showMe`);
            saveUser(data.user);

        } catch (error) {
            removeUser();
        }
        setIsLoading(false);
    };

    const loginUser = async (userInfo) => {
        setIsLoading(true)

        //  Make the API call
        axiosInstance
            .post("/auth/login", {
                email:userInfo.email,
                password:userInfo.password,
            })
            .then((result) => {
                // set the cookie
                // cookies.set("TOKEN", result.data.token, { path: "/", })
                setUser(result.data.user)
            })
            .catch((errorMsg) => {
                setError(errorMsg.response.data.msg)
            })
            .finally(() => {
                setTimeout(() => setError(null), 5000);
            })
    }

    const logoutUser = async () => {
        try {
            await axiosInstance.delete("/auth/logout");
            removeUser();
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchUser();
    }, []);
    

    const checkUserStatus = async () => {
        try { 
            const token = cookies.get("accessToken");

            let userDetails = null; 
            if (token) {
                userDetails = {token: token, name: "Kevin L."} 
                setUser(userDetails)
            } 
        }catch(error) {
            console.log("check user status error logged")
        }
        setIsLoading(false);
    }

    return (
        <AuthContext.Provider
            value={{
                isLoading,
                saveUser,
                user,
                loginUser,
                logoutUser,
                error: error,
            }}>
            {children}
        </AuthContext.Provider>
    );
};
// make sure use
export const useGlobalContext = () => {
    return useContext(AuthContext);
};

export default AuthContext;
